
import { setItem } from '@/utils/local.js'
import { mapGetters } from 'vuex'
const indexMixin = {
  data() {
    return {
      navActiveIndex: 0,
      rules: {
        name: [{ required: true, message: this.$t('m.20004'), trigger: 'change' }],
        mobile: [{ required: true, message: this.$t('m.20006'), trigger: 'change' }],
      },
      showResult: false,
      icon: 'success',
      title: this.$t('m.20017'),
      subTitle: this.$t('m.20018'),
      appComtent: {},
      isRender: false,
    }
  },

  computed: {
    ...mapGetters({
      appData: 'app/appData',
      navList: 'app/navList',
    }),
    // logo
    backgroundUrl() {
      return (this.appData.logo && this.appData.logo.content) || ''
    },
    // 公司介绍
    introduction() {
      return (this.appComtent.introduction && this.appComtent.introduction.content) || {}
    },
    // 企业文化列表
    cultureList() {
      return (this.appComtent.cultureList && this.appComtent.cultureList.content.list) || []
    },
    // 痛点列表
    painPointList() {
      return (this.appComtent.painPointList && this.appComtent.painPointList.content.list) || []
    },
    // 场景列表
    scenesList() {
      return (this.appComtent.scenesList && this.appComtent.scenesList.content.list) || []
    },
    // 设备列表
    deviceList() {
      return (this.appComtent.deviceList && this.appComtent.deviceList.content.list) || []
    },
    // 零门槛上车列表
    zeroThresholdList() {
      return (this.appComtent.zeroThresholdList && this.appComtent.zeroThresholdList.content.list) || []
    },
    // 搭建门店
    createStore() {
      return (this.appComtent.createStore && this.appComtent.createStore.content) || []
    },
    // 外卖
    takeout() {
      return (this.appComtent.takeout && this.appComtent.takeout.content) || {}
    },
    // 外卖平台列表
    takeoutPlatformList() {
      return (this.appComtent.takeoutPlatformList && this.appComtent.takeoutPlatformList.content) || []
    },
    // 智能订单打印
    orderPrint() {
      return (this.appComtent.orderPrint && this.appComtent.orderPrint.content) || {}
    },
    // 智能订单打印列表
    orderPrintList() {
      return (this.appComtent.orderPrintList && this.appComtent.orderPrintList.content.list) || []
    },
    // 经营数据，菜单共享！
    operatingData() {
      return (this.appComtent.operatingData && this.appComtent.operatingData.content) || {}
    },
    operatingDataImg() {
      return (this.appComtent.operatingDataImg && this.appComtent.operatingDataImg.content) || []
    },
    // 全能聚合支付
    aggregatePayment() {
      return (this.appComtent.aggregatePayment && this.appComtent.aggregatePayment.content) || {}
    },
    aggregatePaymentList() {
      return (this.appComtent.aggregatePaymentList && this.appComtent.aggregatePaymentList.content.list) || []
    },
    // 多场景开票服务
    billingService() {
      return (this.appComtent.billingService && this.appComtent.billingService.content) || {}
    },
    billingServiceContent() {
      return (this.appComtent.billingServiceContent && this.appComtent.billingServiceContent.content) || {}
    },
    // “i充电”共享充电服务
    chargeService() {
      return (this.appComtent.chargeService && this.appComtent.chargeService.content) || {}
    },
    chargeServiceList() {
      return (this.appComtent.chargeServiceList && this.appComtent.chargeServiceList.content.list) || []
    },
    // 配套硬件 全国联保
    hardwareList() {
      return (this.appComtent.hardwareList && this.appComtent.hardwareList.content) || {}
    },
    // 专属客服 真挚服务
    about() {
      return (this.appComtent.about && this.appComtent.about.content) || {}
    },
    service() {
      return (this.appComtent.service && this.appComtent.service.content) || {}
    },
  },

  created() {
    //获取内容
    this.$api.getContent({ platform: navigator.userAgent.includes('Mobile') ? 'h5' : 'pc' }).then((res) => {
      if (res.code == 200) {
        this.isRender = true
        this.appComtent = res.data
      }
    })
  },
  mounted() {
    this.importJS()
    window.addEventListener('scroll', this.scrollEvent, false)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollEvent)
  },
  methods: {
    importJS() {
      let _hmt = _hmt || []
      let hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?49355e98ea54827eaf0d63df264b3a9a'
      let s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    },
    handleOk() {
      this.showResult = false
      if (this.icon == 'success') {
        this.$refs['form'].resetFields()
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.h5platform = this.getOS()
          this.$api
            .saveContactus(this.form)
            .then((res) => {
              if (res.code == 200) {
                this.icon = 'success'
                this.title = this.$t('m.20017')
                this.subTitle = this.$t('m.20018')
              } else {
                this.icon = 'error'
                this.title = this.$t('m.20020')
                this.subTitle = res.message
              }
            })
            .catch((err) => {
              this.icon = 'error'
              this.title = this.$t('m.20020')
              this.subTitle = err.message
            })
            .finally(() => {
              this.showResult = true
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleInput(value, attr) {
      let reg = /[^\w\u4E00-\u9FA5-\s]/g
      if (attr == 'mobile') {
        reg = /[^\d]/g
      } else if (attr == 'notes') {
        reg = /[<>]/g
      }
      let val = value.replace(reg, '')
      this.form[attr] = val
    },
    // 切换语言
    selectLang(lang) {
      setItem('lang', lang)
      window.location.reload()
    },
    // 监听页面滚动函数
    scrollEvent(_e) {
      if (this.navList.length === 0) {
        window.removeEventListener('scroll', this.scrollEvent)
        return
      }
      let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop || 0
      const arr = [...this.navList]
      arr.reverse()
      let headerHeight = this.$refs['header'].offsetHeight || 0
      for (const item of arr) {
        if (scrollTop >= this.$refs[item.ref].offsetTop - headerHeight) {
          this.navActiveIndex = item.index
          break
        }
      }
    },

  }
}
export default indexMixin
