<template>
  <div class="home" v-if="isRender">
    <!-- 头部logo、导航start -->
    <header class="header" ref="header">
      <div class="container">
        <div class="logo">
          <a @click="clicknav('banner')">
            <h1 :style="{ background: 'url(' + backgroundUrl + ') 0 0 / cover no-repeat' }">
              i餐饮、iPad POS System、餐饮管理、免费餐饮管理、免费点餐系统、POS、点餐系统、点餐、出菜、营收报表、强大功能
            </h1>
            <span v-if="appData.site_name">{{ appData.site_name.content }}</span>
          </a>
        </div>
        <nav class="navlist">
          <p class="nav-item" :class="{ active: item.index === navActiveIndex }" v-for="(item, index) in navList" :key="item.index" @click="clicknav(item.ref)">
            {{ item.name[$i18n.locale] }}
          </p>
          <p class="nav-item" v-if="appData.backendUrl">
            <a :href="appData.backendUrl.content" target="_blank">{{ $t('m.20001') }}</a>
          </p>
        </nav>
      </div>
    </header>
    <!-- 头部logo、导航end -->

    <!-- banner start -->
    <div class="banner" ref="banner">
      <div class="content">
        <div class="item" v-for="(item, index) in bannerPC" :key="index">
          <div class="item-l">
            <h4>{{ item.text }}</h4>
            <p>{{ item.content }}</p>
            <div class="item-btn" v-if="index == 0">
              <span>{{ $t('m.20002') }}</span>
              <div class="item-popup">
                <div v-if="appData.qrCode">
                  <img :src="appData.qrCode.content" alt="i餐饮" />
                  <p>{{ appData.qrCodeText.title }}</p>
                  <span>{{ appData.qrCodeText.content }}</span>
                </div>
                <div v-if="appData.qrCodeHd">
                  <img :src="appData.qrCodeHd.content" alt="i餐饮HD" />
                  <p>{{ appData.qrCodeHdText.title }}</p>
                  <span>{{ appData.qrCodeHdText.content }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="item-r" :style="{ 'background-image': `url(${item.image})` }"></div>
        </div>
      </div>
    </div>
    <!-- banner end -->

    <!-- 公司简介start -->
    <section ref="aboutUs">
      <!-- 公司介绍 -->
      <section class="intro introduction" :style="{ background: 'url(' + introductionBg + ') center center / cover no-repeat' }">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l white"></span>
            <h5 class="h5-w">{{ introduction.text }}</h5>
            <span class="pro-t-r white"></span>
          </div>
          <p v-for="(item, index) in introduction.list" :key="index">{{ item.text }}</p>
        </div>
        <div class="mask"></div>
      </section>
      <!-- 企业文化 -->
      <section class="intro culture">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ appComtent.cultureList && appComtent.cultureList.content.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <ul>
            <li v-for="(item, index) in cultureList" :key="index">
              <img :src="item.image" alt="" />
              <p>{{ item.text }}</p>
              <span>{{ item.content }}</span>
            </li>
          </ul>
        </div>
      </section>
    </section>
    <!-- 公司简介end -->
    <section ref="icatering">
      <!-- 餐饮企业老板痛点 -->
      <section class="pain-point">
        <div class="pro-title">
          <span class="pro-t-l"></span>
          <h5>{{ appComtent.painPointList && appComtent.painPointList.content.text }}</h5>
          <span class="pro-t-r"></span>
        </div>
        <ul>
          <li v-for="(item, index) in painPointList" :key="index">
            <p>{{ item.text }}</p>
            <p>{{ item.content }}</p>
          </li>
        </ul>
      </section>
      <!-- 餐饮全业态/全场景覆盖 -->
      <section class="business-scene" :style="{ background: 'url(' + businessSceneBg + ') center center / cover no-repeat' }">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l white"></span>
            <h5 class="h5-w">{{ appComtent.scenesList && appComtent.scenesList.content.text }}</h5>
            <span class="pro-t-r white"></span>
          </div>
          <div class="content-title">{{ appComtent.scenesList && appComtent.scenesList.content.content }}</div>
          <ul>
            <li v-for="(item, index) in scenesList" :key="index">
              <img :src="item.image" alt="" />
              <span>{{ item.text }}</span>
            </li>
          </ul>
          <div class="sub-content">
            <div class="sub-content-title">{{ appComtent.deviceList && appComtent.deviceList.content.text }}</div>
            <div class="sub-content-desc">
              {{ appComtent.deviceList && appComtent.deviceList.content.content }}
            </div>
            <ul>
              <li v-for="(item, index) in deviceList" :key="index">
                <img :src="item.image" alt="" />
                <p>{{ item.text }}</p>
              </li>
              <div class="mask"></div>
            </ul>
          </div>
        </div>
        <div class="mask"></div>
      </section>
      <!-- 终生免费使用 -->
      <section class="zero-threshold">
        <div class="pro-title">
          <span class="pro-t-l"></span>
          <h5>{{ appComtent.zeroThreshold && appComtent.zeroThreshold.content.text }}</h5>
          <span class="pro-t-r"></span>
        </div>
        <div class="content">
          <div class="content-l">
            <img :src="appComtent.zeroThreshold && appComtent.zeroThreshold.content.image" alt="" />
          </div>
          <div class="content-r">
            <div v-for="(item, index) in zeroThresholdList" :key="index">
              <p class="title">{{ item.text }}</p>
              <p v-html="item.content"></p>
            </div>
          </div>
        </div>
      </section>
      <!-- 搭建门店 -->
      <section class="create-store" :style="{ background: 'url(' + createStoreBg + ') center center / cover no-repeat' }">
        <div class="content">
          <p>{{ createStore.text }}</p>
          <p>{{ createStore.content }}</p>
          <span></span>
          <p class="desc">
            <span v-for="(item, index) in createStore.list" :key="index">
              {{ item.text }}
            </span>
          </p>
        </div>
        <div class="mask"></div>
      </section>
      <!-- 外卖聚合配送 无惧运力紧张 -->
      <section class="takeout">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ takeout.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <div class="desc">{{ takeout.content }}</div>
          <div class="img">
            <img :src="takeout.image" alt="" />
          </div>
        </div>
        <div class="bg-img" v-if="appComtent.takeoutBg">
          <img :src="appComtent.takeoutBg.content" alt="" />
        </div>
      </section>
      <!-- 「i餐饮」第三方外卖管理 -->
      <section class="takeout-platform" :style="{ background: 'url(' + takeoutPlatformBg + ') center center / cover no-repeat' }">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l white"></span>
            <h5 class="h5-w">{{ appComtent.takeoutPlatform && appComtent.takeoutPlatform.content.text }}</h5>
            <span class="pro-t-r white"></span>
          </div>
          <div class="desc">{{ appComtent.takeoutPlatform && appComtent.takeoutPlatform.content.content }}</div>
          <div class="text">{{ appComtent.takeoutPlatformText && appComtent.takeoutPlatformText.content }}</div>
          <ul>
            <li v-for="(item, index) in takeoutPlatformList" :key="index"><img :src="item" alt="" /></li>
          </ul>
        </div>
        <div class="mask"></div>
      </section>
      <!-- 智能订单打印 -->
      <section class="order-print">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ orderPrint.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <div class="desc">{{ orderPrint.content }}</div>
          <div class="box">
            <div class="box-l">
              <div v-for="(item, index) in orderPrintList" :key="index">
                <p class="title">{{ item.text }}</p>
                <p>{{ item.content }}</p>
              </div>
            </div>
            <div class="box-r">
              <img :src="orderPrint.image" alt="" />
            </div>
          </div>
        </div>
      </section>
      <!-- 多门店 轻管理 经营数据，菜单共享！ -->
      <section class="operating-data">
        <div class="content">
          <div class="content-img" v-if="operatingDataImg[0]">
            <img :src="operatingDataImg[0]" alt="" />
          </div>
          <div class="content-m">
            <p class="title">{{ operatingData.text }}</p>
            <p class="sub-title">{{ operatingData.content }}</p>
            <span></span>
            <p class="text">{{ appComtent.operatingDataText && appComtent.operatingDataText.content }}</p>
            <img :src="operatingData.image" alt="" />
          </div>
          <div class="content-img" v-if="operatingDataImg[1]">
            <img :src="operatingDataImg[1]" alt="" />
          </div>
        </div>
      </section>

      <!-- 全能聚合支付 -->
      <section class="aggregate-payment">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ aggregatePayment.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <div class="desc">{{ aggregatePayment.content }}</div>
          <div class="box">
            <div class="box-l">
              <p v-for="(item, index) in aggregatePaymentList" :key="index">{{ item.text }}</p>
            </div>
            <div class="box-r">
              <img :src="aggregatePayment.image" alt="" />
            </div>
          </div>
        </div>
      </section>

      <!-- 多场景开票服务 -->
      <section class="billing-service">
        <div class="content">
          <div class="pro-title">
            <span class="pro-t-l"></span>
            <h5>{{ billingService.text }}</h5>
            <span class="pro-t-r"></span>
          </div>
          <div class="desc">{{ billingService.content }}</div>
          <div class="box">
            <div class="box-l">
              <p class="title">{{ billingServiceContent.text }}</p>
              <p class="text">{{ billingServiceContent.content }}</p>
            </div>
            <div class="box-r">
              <img :src="billingService.image" alt="" />
            </div>
          </div>
        </div>
      </section>
    </section>

    <!-- “i充电”共享充电服务 -->
    <section class="charge-service" ref="icharge">
      <div class="content">
        <div class="pro-title">
          <span class="pro-t-l"></span>
          <h5>{{ chargeService.text }}</h5>
          <span class="pro-t-r"></span>
        </div>
        <div class="desc">{{ chargeService.content }}</div>
        <div class="box">
          <div class="box-l">
            <div v-for="(item, index) in chargeServiceList" :key="index">
              <p class="title">{{ item.text }}</p>
              <p class="text">{{ item.content }}</p>
            </div>
          </div>
          <div class="box-r">
            <img :src="chargeService.image" alt="" />
          </div>
        </div>
      </div>
    </section>
    <!-- 配套硬件 全国联保 -->
    <section class="hardware-list" ref="product">
      <div class="content">
        <div class="pro-title">
          <span class="pro-t-l"></span>
          <h5>{{ hardwareList.text }}</h5>
          <span class="pro-t-r"></span>
        </div>
        <div class="desc">{{ hardwareList.content }}</div>
        <ul>
          <li v-for="(item, index) in hardwareList.list" :key="index">
            <div>
              <img :src="item.image" alt="" />
              <p>{{ item.text }}</p>
              <span v-if="item.content">{{ item.content }}</span>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <!-- 专属客服 真挚服务start -->
    <section class="about" ref="contactUs">
      <div class="content">
        <div class="pro-title">
          <span class="pro-t-l"></span>
          <h5>{{ about.text }}</h5>
          <span class="pro-t-r"></span>
        </div>
        <div class="desc">{{ about.content }}</div>
        <ul>
          <li v-for="(item, index) in about.list" :key="index">
            <img :src="item.image" alt="" />
            <p>{{ item.text }}</p>
            <span>{{ item.content }}</span>
          </li>
        </ul>
        <div class="contact">
          <div class="title">{{ service.text }}</div>
          <div class="text">{{ service.content }}</div>
          <el-form ref="form" :model="form" :rules="rules" class="form">
            <el-form-item :label="$t('m.20003')" prop="name">
              <el-input @input="(e) => handleInput(e, 'name')" :maxlength="30" v-model="form.name" :placeholder="$t('m.20004')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.20005')" prop="mobile">
              <el-input @input="(e) => handleInput(e, 'mobile')" :maxlength="13" v-model="form.mobile" :placeholder="$t('m.20006')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('m.20007')" prop="notes">
              <el-input @input="(e) => handleInput(e, 'notes')" type="textarea" rows="4" :maxlength="200" v-model="form.notes" :placeholder="$t('m.20008')"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" @click="onSubmit('form')">{{ $t('m.20009') }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </section>
    <!-- 专属客服 真挚服务end -->
    <!-- 底部start -->
    <footer class="footer">
      <div class="content">
        <div class="logo">
          <img v-if="backgroundUrl" :src="backgroundUrl" alt="" />
          <span>{{ appData.site_name && appData.site_name.content }}</span>
        </div>
        <div class="link">
          <div class="link-l">
            <ul>
              <!-- 首页 -->
              <li @click="clicknav('banner')">{{ $t('m.20011') }}</li>
              <!-- 关于我们 -->
              <li @click="clicknav('aboutUs')">{{ $t('m.20014') }}</li>
              <!-- i餐饮 -->
              <li @click="clicknav('icatering')">{{ $t('m.20012') }}</li>
              <!-- i充电 -->
              <li @click="clicknav('icharge')">{{ $t('m.20013') }}</li>
              <!-- 硬件中心 -->
              <li @click="clicknav('product')">{{ $t('m.20021') }}</li>
              <!-- 登录i餐饮 -->
              <li v-if="appData.backendUrl">
                <a :href="appData.backendUrl.content" target="_blank">{{ $t('m.20001') }}</a>
              </li>
            </ul>
            <ul>
              <!-- 联系我们 -->
              <li @click="clicknav('contactUs')">{{ $t('m.20022') }}</li>
              <!-- 服务热线 -->
              <li class="cursor" v-if="appData.service_hotlin">
                {{ appData.service_hotlin.title }}：
                <a :href="`tel:${appData.service_hotlin.content}`">{{ appData.service_hotlin.content }}</a>
              </li>
              <!-- 邮箱 -->
              <li class="cursor" v-if="appData.email">
                {{ appData.email.title }}：
                <a :href="`mailto:${appData.email.content}`">{{ appData.email.content }}</a>
              </li>
            </ul>
          </div>
          <div class="link-r">
            <div v-if="appData.qrCode">
              <img :src="appData.qrCode.content" :alt="appData.qrCodeText.title" />
              <p>{{ appData.qrCodeText.title }}</p>
              <span>{{ appData.qrCodeText.content }}</span>
            </div>
            <div v-if="appData.qrCodeHd">
              <img :src="appData.qrCodeHd.content" :alt="appData.qrCodeHdText.title" />
              <p>{{ appData.qrCodeHdText.title }}</p>
              <span>{{ appData.qrCodeHdText.content }}</span>
            </div>
            <div v-if="appData.qrCodeApplet">
              <img :src="appData.qrCodeApplet.content" :alt="appData.qrCodeAppletText.title" />
              <p>{{ appData.qrCodeAppletText.title }}</p>
              <span>{{ appData.qrCodeAppletText.content }}</span>
            </div>
          </div>
        </div>
        <div class="hr"></div>
        <div class="copyright">
          <p>{{ appData.copyright_repast && appData.copyright_repast.content }}</p>
          <p>
            <a target="_blank" v-if="appData.recordLink" :href="appData.recordLink.content">{{ appData.recordInfo && appData.recordInfo.content }}</a>
          </p>
          <p>
            <a target="_blank" v-if="appData.telecomLink" :href="appData.telecomLink.content">{{ appData.telecom && appData.telecom.content }}</a>
          </p>
        </div>
      </div>
    </footer>
    <!-- 底部end -->
    <!-- 表单提交结果 -->
    <el-dialog :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false" center :title="$t('m.20016')" class="result" :visible.sync="showResult" width="20%">
      <el-result :icon="icon" :title="title" :subTitle="subTitle"> </el-result>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleOk">{{ $t('m.20019') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from '@/mixin/indexMixin'
export default {
  name: 'Home',
  mixins: [indexMixin],
  computed: {
    // bannerPc
    bannerPC() {
      return (this.appComtent.banner && this.appComtent.banner.content.list) || []
    },
    introductionBg() {
      return (this.appComtent.introductionBg && this.appComtent.introductionBg.content) || ''
    },
    businessSceneBg() {
      return (this.appComtent.businessSceneBg && this.appComtent.businessSceneBg.content) || ''
    },
    createStoreBg() {
      return (this.appComtent.createStoreBg && this.appComtent.createStoreBg.content) || ''
    },
    takeoutPlatformBg() {
      return (this.appComtent.takeoutPlatformBg && this.appComtent.takeoutPlatformBg.content) || ''
    },
  },
  data() {
    return {
      form: {
        name: '',
        mobile: '',
        notes: '',
        platform: 'pc',
        email: 'xxx@xxx.xxx',
        source: 9,
      },
      loading: false,
    }
  },

  methods: {
    clicknav(ref) {
      let headerHeight = this.$refs['header'].offsetHeight || 0
      window.scrollTo({
        top: this.$refs[ref].offsetTop - headerHeight + 1,
        behavior: 'smooth',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@mixin mainContent {
  max-width: $mainWidth;
  margin: 0 auto;
  padding: 60px 0;
}
@mixin title($size, $color) {
  font-size: $size;
  @include fontMedium;
  font-weight: 500;
  color: $color;
}
@mixin desc($size, $color) {
  font-size: $size;
  @include fontRegular;
  color: $color;
}
.home {
  padding-top: 64px;
  overflow: hidden;
  // 头部
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: #000;
    z-index: 2021;
    @include flex;
    .container {
      flex: 1;
      max-width: $mainWidth;
      @include flex($align: center, $justify: space-between);
      background-color: #000;
      margin: 0 auto;
      .logo {
        cursor: pointer;
        > a {
          @include flex($align: center);
          > h1 {
            overflow: hidden;
            text-indent: -9999px;
            width: 40px;
            height: 40px;
            margin: 0;
          }
          > span {
            font-size: 24px;
            color: #fff;
            margin-left: 12px;
            font-family: PangMenZhengDao-3, PangMenZhengDao, Arial, Helvetica, sans-serif;
          }
        }
      }
      .navlist {
        @include flex;
        .nav-item {
          cursor: pointer;
          font-size: 16px;
          color: #eaeaea;
          @include fontRegular;
          &:not(:last-child) {
            margin-right: 48px;
          }
          &.active {
            color: #fff;
            @include fontSemibold;
            font-weight: 600;
            border-bottom: 2px solid $red;
          }
          > a {
            font-size: 16px;
            color: #eaeaea;
          }
        }
      }
    }
  }
  // banner
  .banner {
    background-image: -webkit-linear-gradient(360deg, #e9f5fa 0%, #edfafd 49%, #f8fdfe 100%);
    background-image: -o-linear-gradient(360deg, #e9f5fa 0%, #edfafd 49%, #f8fdfe 100%);
    background-image: -moz-linear-gradient(360deg, #e9f5fa 0%, #edfafd 49%, #f8fdfe 100%);
    background-image: linear-gradient(360deg, #e9f5fa 0%, #edfafd 49%, #f8fdfe 100%);
    .content {
      max-width: $mainWidth;
      margin: 0 auto;
      padding-top: 160px;
      .item {
        @include flex;
        .item-l {
          > h4 {
            font-size: 56px;
            @include fontSemibold;
            font-weight: 600;
            color: $black;
            margin-bottom: 12px;
          }
          > p {
            font-size: 44px;
            @include fontRegular;
            color: $black;
            text-align: left;
            letter-spacing: 7px;
          }
        }
        .item-r {
          flex: 1;
          height: 461px;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .content {
    .item-btn {
      margin-top: 48px;
      background-color: $black;
      border-radius: 4px;
      border: 1px solid $black;
      width: 234px;
      text-align: center;
      box-sizing: border-box;
      position: relative;
      > span {
        display: inline-block;
        width: 100%;
        padding: 16px 0;
        cursor: pointer;
        font-size: 20px;
        @include fontMedium;
        font-weight: 500;
        color: #fff;
      }
      .item-popup {
        position: absolute;
        top: 0;
        left: 252px;
        padding: 12px 12px 15px;
        background-color: rgba(0, 0, 0, 0.05);
        display: none;
        > div {
          @include flex($direction: column, $align: center);
          img {
            width: 144px;
            height: 144px;
            flex-shrink: 0;
          }
          > p {
            @include title(14px, $black);
            margin-top: 6px;
          }
          > span {
            @include desc(11px, $black);
            display: inline-block;
            width: 160px;
          }
        }
        &::before {
          content: '';
          border: 12px solid rgba(0, 0, 0, 0.03);
          border-top-color: transparent;
          border-bottom-color: transparent;
          border-left-color: transparent;
          border-right-color: rgba(0, 0, 0, 0.03);
          position: absolute;
          left: -24px;
          top: 20px;
        }
      }
      &:hover {
        .item-popup {
          display: flex;
        }
      }
    }
  }
  // 公司介绍-企业文化
  .intro {
    padding: 60px 0;
    .content {
      max-width: $mainWidth;
      margin: 0 auto;
    }
  }
  // 公司介绍
  .introduction {
    position: relative;
    .mask {
      @include mask(1, rgba(0, 0, 0, 0.5));
    }
    .content {
      position: relative;
      z-index: 2;
      p {
        font-size: 20px;
        color: #f1f1f1;
        @include fontRegular;
        &:not(:last-child) {
          padding-bottom: 32px;
        }
        &:first-of-type {
          font-weight: 500;
          @include fontMedium;
          color: #fff;
          margin-top: 40px;
        }
      }
    }
  }
  .culture {
    .content {
      ul {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        li {
          width: 33.33%;
          padding: 48px 32px 62px;
          text-align: center;
          background-color: #fbfbfb;
          display: flex;
          flex-direction: column;
          align-items: center;
          @include fontMedium;
          box-sizing: border-box;
          &:not(:last-child) {
            margin-right: 24px;
          }
          img {
            width: 60px;
            height: 60px;
            flex-shrink: 0;
          }
          p {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            margin: 8px 0;
          }
          span {
            font-size: 16px;
            @include fontRegular;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }

  .pro-title {
    display: flex;
    justify-content: center;
    align-items: center;
    > h5 {
      font-size: 32px;
      @include fontMedium;
      font-weight: 500;
      color: $black;
      margin: 0 24px;
      &.h5-w {
        color: #fff;
      }
    }
    .pro-t-l,
    .pro-t-r {
      width: 6px;
      height: 20px;
      background-color: $red;
      position: relative;
      &::before {
        position: absolute;
        top: 50%;
        left: -28px;
        transform: translateY(-50%);
        content: '';
        display: inline-block;
        width: 6px;
        height: 12px;
        background-color: $red;
        opacity: 0.4;
      }
      &::after {
        top: 50%;
        left: -14px;
        transform: translateY(-50%);
        position: absolute;
        content: '';
        display: inline-block;
        width: 6px;
        height: 16px;
        opacity: 0.7;
        background-color: $red;
      }
    }
    .pro-t-l.white,
    .pro-t-r.white {
      background-color: #fff;
      &::before {
        background-color: #fff;
        z-index: 2;
      }
      &::after {
        z-index: 2;
        background-color: #fff;
      }
    }
    .pro-t-r {
      &::before {
        left: 28px;
      }
      &::after {
        left: 14px;
      }
    }
  }

  // 产品中心start
  // 痛点
  .pain-point {
    @include mainContent;
    ul {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > li {
        width: 47%;
        flex-grow: 1;
        padding: 20px 32px;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid rgba(255, 17, 17, 0.2);
        margin-bottom: 20px;
        &:nth-child(2n) {
          margin-left: 24px;
        }
        p:first-child {
          font-size: 20px;
          @include fontMedium;
          font-weight: 500;
          color: #333333;
          margin-bottom: 8px;
        }
        p:last-child {
          font-size: 16px;
          @include fontRegular;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }

  // 业务场景
  .business-scene {
    position: relative;
    > .content {
      position: relative;
      z-index: 2;
      max-width: $mainWidth;
      margin: 0 auto;
      padding: 60px 0 26px;
      .content-title {
        font-size: 16px;
        @include fontRegular;
        color: #fff;
        margin: 12px 0 60px;
        text-align: center;
      }
      > ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 50px;
          img {
            width: 64px;
            height: 64px;
            flex-shrink: 0;
            margin-bottom: 12px;
          }
          span {
            font-size: 18px;
            @include fontRegular;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
    .mask {
      @include mask(0.2, #000);
    }
    .sub-content {
      margin-top: 100px;
      text-align: center;
      .sub-content-title {
        font-size: 32px;
        @include fontMedium;
        font-weight: 500;
        color: #fff;
        position: relative;
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 48px;
          height: 2px;
          background-color: #fff;
        }
        &::before {
          left: 32%;
        }
        &::after {
          right: 32%;
        }
      }
      .sub-content-desc {
        margin-top: 12px;
        margin-bottom: 32px;
        padding: 0 138px;
        font-size: 16px;
        @include fontRegular;
        font-weight: 400;
        color: #fff;
      }
      > ul {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 12px 0 20px;
        li {
          flex: 1;
          position: relative;
          z-index: 2;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 144px;
            height: 144px;
          }
          p {
            font-size: 16px;
            @include fontRegular;
            font-weight: 400;
            color: #fff;
          }
        }
        .mask {
          @include mask(0.1, #fff);
        }
      }
    }
  }

  // 零门槛上车
  .zero-threshold {
    @include mainContent;
    .content {
      display: flex;
      position: relative;
      height: 482px;
      margin-top: 32px;
      .content-l {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 730px;
        height: 482px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 428px;
          height: 452px;
        }
      }
      .content-r {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: 500px;
        height: 482px;
        background-color: #fff;
        box-sizing: border-box;
        padding: 0 38px 40px 32px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
        div {
          p {
            font-size: 16px;
            font-weight: 400;
            color: $black;
            @include fontRegular;
            margin-top: 12px;
          }
          p.title {
            @include fontMedium;
            margin-top: 40px;
            font-size: 24px;
            font-weight: 500;
            color: $black;
          }
        }
      }
    }
  }

  // 搭建门店
  .create-store {
    position: relative;
    .content {
      padding: 300px 0;
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      > p {
        font-size: 40px;
        @include fontRegular;
        color: #fff;
      }
      > p:first-of-type {
        margin-bottom: 8px;
      }
      > p.desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        > span {
          font-size: 24px;
          line-height: 36px;
        }
      }
      > span {
        display: inline-block;
        width: 60px;
        height: 8px;
        background: $red;
        margin: 40px 0;
      }
    }
    .mask {
      @include mask(0.4, #000);
    }
  }

  // 外卖聚合配送
  .takeout {
    position: relative;
    height: 840px;
    > .content {
      height: 100%;
      position: relative;
      z-index: 2;
      @include mainContent;
      .desc {
        text-align: center;
        font-size: 16px;
        @include fontRegular;
        color: $black;
        margin-top: 12px;
      }
      .img {
        position: absolute;
        bottom: -26px;
        left: 50%;
        transform: translateX(-112px);
        z-index: 2;
        img {
          width: 325px;
          height: 159px;
        }
      }
    }
    > .bg-img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 840px;
      background-color: #f9f9f9;
      overflow: hidden;
      > img {
        height: 840px;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .takeout-platform {
    position: relative;
    padding: 60px 0 32px;
    .content {
      max-width: $mainWidth;
      margin: 0 auto;
      position: relative;
      z-index: 2;
      .desc,
      .text {
        font-size: 16px;
        @include fontRegular;
        color: #fff;
        text-align: center;
      }
      .desc {
        margin-top: 12px;
        margin-bottom: 32px;
      }
      .text {
        max-width: 842px;
        margin: 0 auto;
      }
      > ul {
        max-width: 842px;
        margin: 88px auto 0;
        display: flex;
        flex-wrap: wrap;
        padding: 0 45px;
        li {
          width: 33.33%;
          margin-bottom: 20px;
          text-align: center;
          img {
            width: 160px;
            height: 160px;
          }
        }
      }
    }
    .mask {
      @include mask(1, rgba(0, 0, 0, 0.2));
    }
  }
  // 订单打印
  .order-print {
    background-color: #f9f9f9;
    .content {
      @include mainContent;
      .desc {
        text-align: center;
        margin: 12px auto 32px;
        font-size: 16px;
        @include fontRegular;
        color: $black;
      }
      .box {
        background-color: #fff;
        display: flex;
        .box-l {
          width: 480px;
          padding: 40px 48px 40px 32px;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
          div {
            p {
              font-size: 16px;
              @include fontRegular;
              color: $black;
              margin-top: 12px;
            }
            p.title {
              font-size: 24px;
              @include fontMedium;
              font-weight: 500;
              margin-top: 0;
            }
            &:not(:first-of-type) {
              margin-top: 40px;
            }
          }
        }
        .box-r {
          flex: 1;
          text-align: center;
          flex-shrink: 0;
          padding: 31px 0;
          img {
            width: 420px;
            height: 420px;
          }
        }
      }
    }
  }

  // 经营数据，菜单共享！
  .operating-data {
    .content {
      width: 1168px;
      margin: 0 auto;
      padding-top: 60px;
      display: flex;
      justify-content: space-between;
      .content-img {
        flex-shrink: 0;
        img {
          width: 242px;
          height: 332px;
        }
      }
      .content-m {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 22px;
        p {
          font-size: 24px;
          @include fontRegular;
          color: $black;
          text-align: center;
        }
        p.title,
        p.sub-title {
          font-size: 40px;
        }
        p.title {
          margin-top: 3px;
          margin-bottom: 8px;
        }
        > span {
          display: inline-block;
          width: 60px;
          height: 8px;
          margin: 40px 0;
          background-color: $red;
        }

        img {
          width: 517px;
          height: 152px;
          flex-shrink: 0;
          margin-top: 40px;
        }
      }
    }
  }

  // 全能聚合支付
  .aggregate-payment {
    background-color: #f9f9f9;
    .content {
      @include mainContent;
      .desc {
        text-align: center;
        font-size: 16px;
        @include fontRegular;
        color: $black;
        margin: 12px 0 32px;
      }
      .box {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        .box-l {
          padding: 60px 36px 60px 44px;
          width: 500px;
          height: 482px;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
          > p {
            font-size: 20px;
            @include fontRegular;
            color: $black;
            position: relative;
            &::before {
              content: '';
              display: block;
              position: absolute;
              top: 6px;
              left: -12px;
              width: 4px;
              height: 18px;
              background: #ee1c22;
            }
            &:not(:first-child) {
              margin-top: 40px;
            }
          }
        }
        .box-r {
          flex: 1;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          img {
            width: 434px;
            height: 434px;
          }
        }
      }
    }
  }

  // 多场景开票服务
  .billing-service {
    .content {
      @include mainContent;
      .desc {
        text-align: center;
        font-size: 16px;
        @include fontRegular;
        color: $black;
        margin: 12px 0 32px;
      }
      .box {
        display: flex;
        justify-content: space-between;
        .box-l {
          width: 480px;
          background-color: #fff;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
          padding: 120px 48px 32px 32px;
          p.title {
            font-size: 24px;
            @include fontMedium;
            font-weight: 500;
            color: #333333;
          }
          p.text {
            margin-top: 12px;
            font-size: 16px;
            @include fontRegular;
            color: #333333;
          }
        }
        .box-r {
          flex: 1;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 33px 0 29px;
          img {
            width: 434px;
            height: 434px;
          }
        }
      }
    }
  }
  // “i充电”共享充电服务
  .charge-service {
    background-color: #f9f9f9;
    .content {
      @include mainContent;
      .desc {
        text-align: center;
        font-size: 16px;
        @include fontRegular;
        color: $black;
        margin: 12px 0 32px;
      }
      .box {
        display: flex;
        justify-content: space-between;
        .box-l {
          width: 480px;
          background-color: #fff;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
          padding: 40px 79px 40px 32px;
          div {
            &:not(:first-of-type) {
              margin-top: 40px;
            }
            p.title {
              font-size: 24px;
              @include fontMedium;
              font-weight: 500;
              color: #333333;
            }
            p.text {
              margin-top: 12px;
              font-size: 16px;
              @include fontRegular;
              color: $black;
            }
          }
        }
        .box-r {
          flex: 1;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 49px 0 47px;
          img {
            width: 434px;
            height: 434px;
          }
        }
      }
    }
  }

  // 配套硬件 全国联保
  .hardware-list {
    .content {
      max-width: $mainWidth;
      margin: 0 auto;
      padding: 60px 0 40px;
      .desc {
        text-align: center;
        font-size: 16px;
        @include fontRegular;
        color: $black;
        margin: 12px 0 32px;
      }
      > ul {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        li {
          margin-bottom: 20px;
          text-align: center;
          width: 20%;
          padding: 0 8px;
          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: linear-gradient(180deg, #fcf8f8 0%, #f4f3f3 100%);
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
            border-radius: 16px;
            box-sizing: border-box;
            padding: 24px 17px 20px;
            max-height: 264px;
            img {
              width: 184px;
              height: 184px;
            }
            p {
              font-size: 16px;
              @include fontRegular;
              color: $black;
              margin-top: 12px;
            }
            span {
              font-size: 14px;
              @include fontRegular;
              color: $black;
            }
          }
          &:nth-child(-n + 3) {
            width: 33.33%;
          }
        }
      }
    }
  }
  // 产品中心end

  // 专属客服 真挚服务
  .about {
    background-image: linear-gradient(180deg, #ffffff 0%, #fff7f7 100%);
    .content {
      @include mainContent;
      > .desc {
        text-align: center;
        font-size: 16px;
        @include fontRegular;
        color: $black;
        margin: 12px 0 32px;
      }
      ul {
        display: flex;
        max-width: 880px;
        margin: 0 auto;
        li {
          width: 33.33%;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 84px;
            height: 84px;
            flex-shrink: 0;
          }
          p {
            font-size: 24px;
            @include fontMedium;
            font-weight: 500;
            color: $black;
            margin-top: 12px;
          }
          span {
            font-size: 16px;
            @include fontRegular;
            color: $black;
          }
        }
      }
      .contact {
        margin-top: 40px;
        padding: 60px 157px 40px;
        background-color: #fff;
        text-align: center;
        .title {
          font-size: 24px;
          @include fontMedium;
          font-weight: 500;
          color: $black;
        }
        .text {
          font-size: 16px;
          @include fontRegular;
          color: #777777;
          margin-top: 12px;
        }
        .form {
          width: 390px;
          margin: 20px auto 0;
          ::v-deep .el-form-item__label {
            font-size: 16px;
            @include fontMedium;
            font-weight: 500;
            color: $black;
          }
          ::v-deep .el-input__inner {
            color: $black;
          }
          ::v-deep .el-input__inner:focus,
          ::v-deep .el-textarea__inner:focus {
            border-color: $red;
          }
          .el-button {
            margin-top: 20px;
            width: 100%;
            background-color: $red;
            border-radius: 4px;
            font-size: 18px;
            @include fontMedium;
            font-weight: 500;
            color: #fff;
            padding: 12px 0;
            border-color: $red;
          }
        }
      }
    }
  }

  // 底部
  .footer {
    background-color: #1e1f20;
    .content {
      max-width: $mainWidth;
      margin: 0 auto;
      padding: 40px 0 16px;
      .logo {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
        }
        span {
          font-size: 16px;
          @include fontSemibold;
          font-weight: 600;
          color: #fff;
          margin-left: 12px;
        }
      }
      .link {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        .link-l {
          display: flex;
          justify-content: space-between;
          flex: 1;
          ul {
            flex: 2;
            &:first-child {
              flex: 1;
            }
            li,
            a {
              font-size: 14px;
              @include fontRegular;
              color: #eaeaea;
            }
            li {
              cursor: pointer;
            }
            li:first-of-type {
              @include fontSemibold;
              font-weight: 600;
              color: #fff;
            }
            li:not(:first-of-type) {
              margin-top: 8px;
            }
            li.cursor {
              cursor: default;
            }
          }
        }
        .link-r {
          display: flex;
          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
              width: 100px;
              height: 100px;
              flex-shrink: 0;
            }
            p {
              font-size: 14px;
              @include fontMedium;
              font-weight: 500;
              color: #fff;
              margin-top: 12px;
            }
            span {
              font-size: 11px;
              @include fontRegular;
              color: #eaeaea;
            }
            &:not(:last-child) {
              margin-right: 12px;
            }
          }
        }
      }
      .hr {
        height: 1px;
        border: 0;
        background-color: #595959;
      }
      .copyright {
        display: flex;
        margin-top: 15px;
        p {
          font-size: 12px;
          @include fontRegular;
          color: #bfbfbf;
        }
        a {
          font-size: 12px;
          @include fontRegular;
          color: #bfbfbf;
          margin-left: 10px;
          &:hover {
            color: $red;
          }
        }
      }
    }
  }

  .result {
    ::v-deep .el-result {
      padding: 0;
    }
    .dialog-footer {
      ::v-deep .el-button {
        background-color: $red;
        color: #fff;
        padding: 12px 40px;
        border-color: $red;
      }
    }
  }
}
</style>
